import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box } from "@mui/system";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import { useStyles } from "../styles";
import { QuizStepProps } from "..";
import { TasteProfileTabs } from "../components/TasteProfileTabs";
import QuizRecommendationCard from "../components/QuizRecommendationCard";
import YourProfileIs from "../../../assets/quiz/yourprofileis.svg";
import YourProfile from "../../../assets/quiz/yourprofile.svg";
import YourProfileIsMobile from "../../../assets/quiz/yourprofileismobile.svg";
import { Add } from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const QuizStep5 = ({
  setQuizStep,
  cocktails = [],
  lang = "",
  iframe = 'stand',
  cookie = null,
  sessionId = null,
  tabsDefinition = { tabs: [] },
}: QuizStepProps) => {
  const classes = useStyles();

  const [showAllCocktails, setShowAllCocktails] = useState(false);
  const theme = useTheme();
  const isTabletOrLarger = useMediaQuery(theme.breakpoints.up('sm'));
  const px500to600 = useMediaQuery('(min-width:500px) and (max-width:650px)');
  const px600to750 = useMediaQuery('(min-width:651px) and (max-width:750px)');
  const px750to900 = useMediaQuery('(min-width:750px) and (max-width:900px)');
  const px385to500 = useMediaQuery('(min-width:405px) and (max-width:500px)');
  const px389to404 = useMediaQuery('(min-width:389px) and (max-width:404px)');
  const px375to380 = useMediaQuery('(min-width:375px) and (max-width:380px)');

  // State to hold the number of items to show
  const [itemsToShow, setItemsToShow] = useState(1); // Default to showing 1 item
  const horizontalCarouselRef = useRef<any>(null);


  const [queryValue, setQueryValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get('cocktails');
    setQueryValue(value >= 1 && value <= 3 ? parseInt(value) : 3);
  }, []);


  console.log("tabsDefinition ======= ", tabsDefinition)

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "custom_event",
      event_name: "quiz_complete",
      step: "success",
    });
  }, []);
  useEffect(() => {
    if (px500to600) {
      setItemsToShow(1.5);
    }
    else if (px600to750) {
      setItemsToShow(1.8)
    }
    else if (px750to900) {
      setItemsToShow(2.15)
    } else if (px385to500) {
      setItemsToShow(1.2);
    } else if (px389to404) {
      setItemsToShow(1.15);
    } else if (px375to380) {
      setItemsToShow(1.10);
    }
    else if (isTabletOrLarger) {
      setItemsToShow(3);
    } else {
      setItemsToShow(1);
    }
  }, [isTabletOrLarger, px500to600, px600to750, px750to900, px385to500, px389to404, px375to380]);
  const cocktailIds = cocktails.map(cocktail => cocktail.cocktailId);

  const whatsyourcocktailtitle = useMemo(
    () => ({
      EN_UK: "What's your cocktail?",
      EN_US: "What's your cocktail?",
      EN_GB: "What's your cocktail?",
      EN_ROW: "What's your cocktail?",
      ES: "¿CUÁL ES TU CÓCTEL?",
      ES_ROW: "¿CUÁL ES TU CÓCTEL?",
      ES_US: "¿CUÁL ES TU CÓCTEL?",
      ES_ES: "¿CUÁL ES TU CÓCTEL?",
      ES_MX: "¿CUÁL ES TU CÓCTEL?",
    }),
    []
  );

  const ourAItitle = useMemo(
    () => ({
      EN_UK: "Our AI has crafted a selection of cocktails just for you.",
      EN_US: "Our AI has crafted a selection of cocktails just for you.",
      EN_ROW: "Our AI has crafted a selection of cocktails just for you.",
      EN_GB: "Our AI has crafted a selection of cocktails just for you.",
      ES: "Nuestra IA ha seleccionado unos cócteles perfectos para ti.",
      ES_ROW: "Nuestra IA ha seleccionado unos cócteles perfectos para ti.",
      ES_US: "Nuestra IA ha seleccionado unos cócteles perfectos para ti.",
      ES_ES: "Nuestra IA ha seleccionado unos cócteles perfectos para ti.",
      ES_MX: "Nuestra IA ha seleccionado unos cócteles perfectos para ti.",
    }),
    []
  );

  const showmoretitle = useMemo(
    () => ({
      EN_UK: "SHOW MORE COCKTAILS",
      EN_US: "SHOW MORE COCKTAILS",
      EN_GB: "SHOW MORE COCKTAILS",
      EN_ROW: "SHOW MORE COCKTAILS",
      ES: "VER MÁS CÓCTELES",
      ES_ROW: "VER MÁS CÓCTELES",
      ES_US: "VER MÁS CÓCTELES",
      ES_ES: "VER MÁS CÓCTELES",
      ES_MX: "VER MÁS CÓCTELES",
    }),
    []
  );

  const discovertitle = useMemo(
    () => ({
      EN_UK: "Discover",
      EN_US: "Discover",
      EN_ROW: "Discover",
      ES: "Ver",
      ES_ROW: "Ver",
      ES_US: "Ver",
      ES_ES: "Ver",
      ES_MX: "Ver",
    }),
    []
  );

  const startagaintitle = useMemo(
    () => ({
      EN_UK: "START AGAIN",
      EN_US: "START AGAIN",
      EN_GB: "RESTART",
      EN_ROW: "START AGAIN",
      ES: "EMPEZAR DE NUEVO",
      ES_ROW: "EMPEZAR DE NUEVO",
      ES_US: "EMPEZAR DE NUEVO",
      ES_ES: "EMPEZAR DE NUEVO",
      ES_MX: "EMPEZAR DE NUEVO",
    }),
    []
  );

  const yourprofileimage = useMemo(
    () => ({
      EN_UK: YourProfileIs,
      EN_US: YourProfileIs,
      EN_ROW: YourProfileIs,
      ES: YourProfile,
      ES_ROW: YourProfile,
      ES_US: YourProfile,
      ES_ES: YourProfile,
      ES_MX: YourProfile,
    }),
    []
  );

  const yourprofileimageMobile = useMemo(
    () => ({
      EN_UK: YourProfileIsMobile,
      EN_US: YourProfileIsMobile,
      EN_ROW: YourProfileIsMobile,
      ES: YourProfile,
      ES_ROW: YourProfile,
      ES_US: YourProfile,
      ES_ES: YourProfile,
      ES_MX: YourProfile,
    }),
    []
  );



  const title = whatsyourcocktailtitle[lang] || "What's your cocktail?";
  const titleAI = ourAItitle[lang] || "Our AI has crafted a selection of cocktails just for you.";
  const titleshowmore = showmoretitle[lang] || "SHOW MORE COCKTAILS";
  const titlediscover = discovertitle[lang] || "Discover";
  const titlestartagain = startagaintitle[lang] || "START AGAIN";
  const imageyourprofile = yourprofileimage[lang] || YourProfileIs;

  const imageyourprofilemobile = yourprofileimageMobile[lang] || YourProfileIsMobile;

  const getButtonClassName = () => {
    switch (lang) {
      case 'EN_UK':
        return classes.startAgainButtonDesktop;
      case 'EN_US':
        return classes.startAgainButtonDesktop;
      case 'EN_ROW':
        return classes.startAgainButtonDesktop;
      case 'ES':
        return classes.startAgainButtonDesktopES;
      case 'ES_ES':
        return classes.startAgainButtonDesktopES;
      case 'ES_MX':
        return classes.startAgainButtonDesktopES;
      case 'ES_US':
        return classes.startAgainButtonDesktopES;
      case 'ES_ROW':
        return classes.startAgainButtonDesktopES;

      default:
        return classes.startAgainButtonDesktop;
    }
  };

  const getButtonClassNameMobile = () => {
    switch (lang) {
      case 'EN_UK':
        return classes.startAgainButtonMobile;
      case 'EN_US':
        return classes.startAgainButtonMobile;
      case 'EN_ROW':
        return classes.startAgainButtonMobile;
      case 'ES':
        return classes.startAgainButtonMobileES;
      case 'ES_ES':
        return classes.startAgainButtonMobileES;
      case 'ES_MX':
        return classes.startAgainButtonMobileES;
      case 'ES_US':
        return classes.startAgainButtonMobileES;
      case 'ES_ROW':
        return classes.startAgainButtonMobileES;

      default:
        return classes.startAgainButtonMobile;
    }
  };

  return (
    <Box
      className={classes.thirdStepRoot}
      sx={{
        padding: {
          xs: "0rem !important",
          sm: "0rem !important",
          md: "0rem 4rem 2rem 2rem !important",
          lg: "0rem 4rem 2rem 2rem !important",
          xl: "0rem 4rem 2rem 2rem !important",
        },
        flexDirection: {
          xs: "column !important",
          sm: "column !important",
          md: "row !important",
          lg: "row !important",
          xl: "row !important",
        },
        justifyContent: {
          xs: "unset !important",
          sm: "unset !important",
          md: "center !important",
          lg: "center !important",
          xl: "center !important",
        },
      }}
    >
      {/* Desktop */}
      <Grid container component={Box} display={{ xs: "none", md: "flex" }}>
        <Grid
          item
          md={12}
          sx={{
            // height: "100vh",
            // overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box className={classes.thirdStepDescriptionContainer}>
            <Box>
              <Typography gutterBottom className={classes.WYCTitle}>
                {title}
              </Typography>
            </Box>

            <Box sx={{ width: "720px" }}>
              <Typography gutterBottom className={classes.secondStepTitle}>
                {titleAI}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <img src={imageyourprofile} alt="Your profile is" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        component={Box}
        display={{ xs: "none", md: "flex" }}
        sx={{ marginTop: "1rem" }}
      >
        <Grid item md={6} sx={{ paddingLeft: "40px" }}>
          <TasteProfileTabs tabs={tabsDefinition.tabs} />
        </Grid>
        <Grid
          item
          md={6}
          sx={{ display: "flex", alignItems: "end", justifyContent: "end" }}
        >
          <Button
            fullWidth
            className={getButtonClassName()}
            onClick={() => setQuizStep(1)}
          >
            <Grid container sx={{ justifyContent: "space-around" }}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Box width={"100%"}>{titlestartagain}</Box>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        component={Box}
        display={{ xs: "none", md: "flex" }}
        sx={{ marginTop: "1rem", paddingLeft: "40px" }}
        spacing={2}
        className="cocktail-list"
      >

        {cocktails
          .filter((item, index) => index < queryValue)
          .map((cocktail, index) => (
            <Grid item md={4}>
              <QuizRecommendationCard {...cocktail} cocktailIds={cocktailIds} titlediscover={titlediscover} iframe={iframe} cookie={cookie} sessionId={sessionId} />
            </Grid>
          ))}
        {showAllCocktails && (
          <>
            {cocktails
              .filter((item, index) => index > 2)
              .map((cocktail, index) => (
                <Grid item md={4}>
                  <QuizRecommendationCard {...cocktail} cocktailIds={cocktailIds} titlediscover={titlediscover} iframe={iframe} cookie={cookie} sessionId={sessionId}/>
                </Grid>
              ))}
          </>
        )}
      </Grid>
      {(!showAllCocktails && iframe !== "worldclass") && (
        <Grid container component={Box} display={{ xs: "none", md: "flex" }}>
          <Grid
            item
            xs={12}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "custom_event",
                event_name: "show_more",
              });
              setShowAllCocktails(true);
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <IconButton
                sx={{
                  border: "1px solid #2c2c2c",
                  color: "#2c2c2c",
                  marginRight: "1rem",
                }}
              >
                <Add fontSize="small" />
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "HelveticaNowDisplay-Medium !important",
                  fontSize: "14px",
                  fontWeight: "700",
                  letterSpacing: "2px",
                }}
              >
                {titleshowmore}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      {/* Mobile */}
      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ paddingRight: "1rem" }}
      >
        <Grid item xs={12}>
          <Box
            className={classes.fourthStepDescriptionContainer}
            sx={{ padding: "0rem !important", paddingTop: "0 !important" }}
          >
            <Box>
              <Typography className={classes.WYCTitleMobile}>
                {title}
              </Typography>
            </Box>
            <Box sx={{ maxWidth: "95%" }}>
              <Typography gutterBottom className={classes.firstStepTitleMobile}>
                {titleAI}
              </Typography>
            </Box>
            <Box>
              <img src={imageyourprofilemobile} alt="Your profile is" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ marginTop: "1rem" }}
      >
        <Grid item xs={12} sx={{ paddingLeft: "1rem" }}>
          <TasteProfileTabs tabs={tabsDefinition.tabs} mobile />
        </Grid>
      </Grid>
      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ margin: "1rem 0" }}
      >
        <Grid item xs={12} sx={{ paddingLeft: "1rem" }}>
          <Button
            fullWidth
            className={getButtonClassNameMobile()}
            sx={{ width: "174px !important" }}
            onClick={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "in_page_button",
                click_text: "Start Again",
                destination_page_path: window.location.pathname,
                link_url: window.location.href,
                detail: "Start Again",
              });
              console.log("Data Layer after event push:", window.dataLayer);
              setQuizStep(1);
            }}
          >
            <Grid container sx={{ justifyContent: "space-around" }}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Box width={"100%"}>{titlestartagain}</Box>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        component={Box}
        display={{ xs: "block", md: "none" }}
        sx={{ marginTop: "1rem" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            minHeight: "514px",
            "& .rec-item-wrapper": {
              padding: "0px !important",
            },
            "& .rec-dot": {
              backgroundColor: "#FCD7A4 !important",
              border: "none !important",
              boxShadow: "#FCD7A4 0px 0px 1px 3px !important",
            },
            "& .rec-dot_active": {
              backgroundColor: "#E07900 !important",
              border: "none !important",
              boxShadow: "#E07900 0px 0px 1px 3px !important",
            },
          }}
        >
          {!showAllCocktails && (
            <Carousel
              ref={horizontalCarouselRef}
              itemsToShow={itemsToShow}
              autoPlaySpeed={1500}
              renderArrow={() => <></>}
              itemPadding={[0, 0, 0, 10]}
            >
              {cocktails
                .filter((item, index) => index <= 3)
                .map((cocktail, index) => (
                  <Grid item md={4}>
                    <QuizRecommendationCard {...cocktail} cocktailIds={cocktailIds} titlediscover={titlediscover} iframe={iframe} cookie={cookie} sessionId={sessionId} mobile />
                  </Grid>
                ))}
              <Box
                sx={{
                  width: "311px",
                  height: "479px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#b4accb",
                  borderRadius: "8px !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: "custom_event",
                    event_name: "show_more",
                  });
                  setShowAllCocktails(true);
                }}
              >
                <IconButton
                  sx={{
                    border: "1px solid #2c2c2c",
                    color: "#2c2c2c",
                    marginRight: "1rem",
                    width: "80px",
                    height: "80px",
                    marginBottom: "2rem",
                  }}
                >
                  <Add sx={{ fontSize: "40px" }} />
                </IconButton>
                <Box width={"116px"}>
                  <Typography
                    sx={{
                      fontFamily: "HelveticaNowDisplay-Medium !important",
                      fontSize: "16px",
                      fontWeight: "700",
                      letterSpacing: "2px",
                    }}
                  >
                    {titleshowmore}
                  </Typography>
                </Box>
              </Box>
            </Carousel>
          )}
          {showAllCocktails && (
            <Carousel
              itemsToShow={itemsToShow}
              autoPlaySpeed={1500}
              renderArrow={() => <></>}
              itemPadding={[0, 0, 0, 10]}
              initialActiveIndex={4}
            >
              {cocktails.map((cocktail, index) => (
                <Grid item md={4}>
                  <QuizRecommendationCard {...cocktail} cocktailIds={cocktailIds} titlediscover={titlediscover} iframe={iframe} cookie={cookie} sessionId={sessionId} mobile />
                </Grid>
              ))}
            </Carousel>
          )}
        </Grid>
      </Grid>


    </Box>
  );
};

export default QuizStep5;
