/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import FontUK from "../../assets/FontUK.png";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";

import { v4 as uuid } from 'uuid';
import {
  CreateConsumer,
  GetCocktailsByLanguage,
  GetConsumer,
  UpdateConsumer,
} from "../../graphql_queries";

function PocDiageo(props) {

  // Constants
  const searchParams = new URLSearchParams(window.location.search);
  
  const CLOUDFRONT_URL = process.env.REACT_APP_CDN;

  const siteMapper = useMemo(
    () => ({
      bbcgoodfood: "uk",
      simplyrecipes: "us",
      allrecipes: "us",
    }),
    []
  );

  const siteLangMapper = useMemo(
    () => ({
      bbcgoodfood: "EN_UK",
      simplyrecipes: "EN_US",
      allrecipes: "EN_US",
    }),
    []
  );


  const api_url = process.env.REACT_APP_FLAVORPRINT_API_URL;
  const api_key = process.env.REACT_APP_FLAVORPRINT_API_KEY;
  const buttonTextOptions = useMemo(() => ["View recipe"], []);
  const flavorsAndEmojis = useMemo(
    () => [
      { flavor: "Berry", emoji: "🍓", unicode: "U+1F353" },
      { flavor: "Bitter", emoji: "🥃", unicode: "U+1F943" },
      { flavor: "Caramelized", emoji: "🍮", unicode: "U+1F36E" },
      { flavor: "Caramel", emoji: "🍮", unicode: "U+1F36E" },
      { flavor: "Chocolate", emoji: "🍫", unicode: "U+1F36B" },
      { flavor: "Citrus", emoji: "🍊", unicode: "U+1F34A" },
      { flavor: "Coffee", emoji: "☕", unicode: "U+2615" },
      { flavor: "Creamy", emoji: "🥛", unicode: "U+1F95B" },
      { flavor: "Botanical", emoji: "🌸", unicode: "U+1F338" },
      { flavor: "Fruity", emoji: "🍉", unicode: "U+1F349" },
      { flavor: "Herbal", emoji: "🌿", unicode: "U+1F33F" },
      { flavor: "Mint", emoji: "🍃", unicode: "U+1F343" },
      { flavor: "Nutty", emoji: "🥜", unicode: "U+1F95C" },
      { flavor: "Smoky", emoji: "💨", unicode: "U+1F32A" },
      { flavor: "Sour", emoji: "🍋", unicode: "U+1F34B" },
      { flavor: "Hot", emoji: "🔥", unicode: "U+1F525" },
      { flavor: "Sweet", emoji: "🍭", unicode: "U+1F36D" },
      { flavor: "Toasted", emoji: "🌾", unicode: "U+1F33E" },
      { flavor: "Tropical", emoji: "🌴", unicode: "U+1F334" },
      { flavor: "Vanilla", emoji: "🍦", unicode: "U+1F366" },
      { flavor: "Spice", emoji: "🌶️", unicode: "U+1F336" },
      { flavor: "Woody", emoji: "🪵", unicode: "U+1F333" },
      { flavor: "Savory", emoji: "", unicode: "U+1F9C2" },
    ],
    []
  );
  const MoreOptions = ["Reveal more cocktails", "Not quite right, try again"];
  const availableTitles = [
    "You love [FLAVOR 1] and [FLAVOR 2] flavors, these cocktails are for you",
  ];
  let buttonTextIndex = 0;

  // Params
  const _site = searchParams.get("site") || "";
  const _recipe = searchParams.get("recipe") || "";
  const _window_width = searchParams.get("window_width") || "";
  const _geolocation = searchParams.get("geolocation") || "0";
  const _recipe_url = searchParams.get("recipe_url") || "";


  const _cookie = searchParams.get("cookie") || null;

  const ParentCookie = _cookie

  let cookieName = "widgetcookie";

  //Cookies.set(cookieName, _cookie)

  let useGraphQL = false;

  let region = "us";
  region = siteMapper[_site] || "us";
  const wlanguage = useMemo(
    () => siteLangMapper[_site] || "EN_US",
    [_site, siteLangMapper]
  );
  // Props destructuring
  const { textColor, backgroundColor } = props;
  const { primaryColor, secondaryColor, bgColor } = props;

  // States
  const [LegalText, setLegalText] = useState(null);
  const [Title, setTitle] = useState(null);
  const [SubTitle, setSubTitle] = useState(null);
  const [items, setItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [TextMoreOptions, setTextMoreOptions] = useState(
    MoreOptions[currentIndex]
  );
  const [clickCount, setClickCount] = useState(0);
  const [SessionCookie, setSessionCookie] = useState(null);
  const [isMobile, setIsMobile] = useState(_window_width < 768);
  const [isLoading, setIsLoading] = useState(false);
  const [locationData, setLocationData] = useState({
    city: null,
    country: null,
    region: null,
    latitude: null,
    longitude: null,
  });
  const [userAgentData, setuserAgentData] = useState({
    user_agent: null
  });

  const [sessionId, setSessionId] = useState(null);

  // Generate a random title
  const randomIndex = Math.floor(Math.random() * availableTitles.length);
  const randomTitle = availableTitles[randomIndex];

  // Effect for updating styles
  useEffect(() => {
    if (primaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      );
    }
    if (secondaryColor) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor
      );
    }
    if (bgColor) {
      document.documentElement.style.setProperty("--bg-color", bgColor);
    }
  }, [primaryColor, secondaryColor, bgColor]);

  // Effect for initializing the widget
  useEffect(() => {
    const getCocktailsFromFlavourPrintAndGraphQL = async (params) => {

    // Create variables inside the useEffect
    const unixTimestamp = Math.floor(Date.now() / 1000); // Get the Unix timestamp (in seconds)
    const hexTimestamp = unixTimestamp.toString(16); // Convert it to hex
    
    // Create the sessionId using the variables
    const generatedSessionId = String(hexTimestamp) + String(uuid());

    // Set the sessionId to the state
    setSessionId(generatedSessionId);

    params.sessionToken = generatedSessionId

  
    let url = null;
    let useGraphQL = false;

    url = new URL(api_url + "recommendation");
    useGraphQL = true;


    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );


    console.log("params recommended", params)

      try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "x-api-key": String(api_key),
        },
      });

      if (!response.ok) {
        setTitle("Configuration Error");
        setTextMoreOptions("");
        setIsLoading(false);
        return; // Exit the function if the response is not OK
      }

      const data = await response.json();
      await processResponse(data, useGraphQL);
    } catch (fallbackError) {
      console.error("Both primary and fallback requests failed", fallbackError);
      setTitle("Configuration Error");
      setTextMoreOptions("Unable to fetch data from both endpoints.");
      setIsLoading(false);
    }
  
};


    const processResponse = (data, useGraphQL) => {
          setLegalText("PLEASE DRINK RESPONSIBLY, Diageo, New York, NY");
    const consumerTopFlavors = data.consumerTopFlavors;
    const page_title = randomTitle
      .replace("[FLAVOR 1]", consumerTopFlavors[0])
      .replace("[FLAVOR 2]", consumerTopFlavors[1]);
    setTitle(page_title);
    document.title = page_title;
    setSessionCookie(data.consumerId);

    const items_api = [];
    
    let cocktailNamesCookie = "";

    const params = {
      site: _site,
      recipes: [_recipe],
      url: _recipe_url,
      interactionType: "View", 
      occasions: [],
      cuisines: [],
      sessionToken: sessionId,
      consumerId: data?.consumerId,
    };


    saveInteractionAPI([params]);

    const cocktail_names = [];

    for (const recommendation of data.cocktailRecommendations) {
      cocktail_names.push(recommendation.cocktailId);
    }

    GetCocktailsByLanguage(cocktail_names, "EN_UK").then((cocktailData) => {
      for (let i = 0; i < data.cocktailRecommendations.length; i++) {
        cocktailNamesCookie += `${data.cocktailRecommendations[i].cocktailId};;`;
        const graphqlData = cocktailData[i];

        const lifeStyleImages = graphqlData.images
          ? graphqlData.images.filter(
              (image) => image.imageType === "widget_thumbnail"
            )
          : [];
        let lifeStyleImageUrl = "";
        if (lifeStyleImages.length > 0) {
          const url = `${CLOUDFRONT_URL}/public/${lifeStyleImages[0].imageUrl.split("/public/").slice(-1)[0]}`;
          lifeStyleImageUrl = `${url.replace(/ /g, '%20')}`;
        }

        if (useGraphQL) {
          items_api.push({
            cocktailId: data.cocktailRecommendations[i].cocktailId,
            sourceId: graphqlData.cocktailTitle,
            link: graphqlData.cocktail_url + `?utm_source=${_site}&utm_medium=referral&utm_campaign=whatsyourcocktail`,
            title: graphqlData.cocktailTitle,
            image: lifeStyleImageUrl,
            description: graphqlData.cocktailDescription,
            cocktailTopFlavors: data.cocktailRecommendations[i].cocktailTopFlavors,
            subtitle: data.cocktailRecommendations[i].cocktailTopFlavors.slice(0, 2).join(" and "),
            buttonText: buttonTextOptions[buttonTextIndex++ % buttonTextOptions.length],
            emojis: data.cocktailRecommendations[i].cocktailTopFlavors.map(
              (flavor) => {
                const flavorInfo = flavorsAndEmojis.find(
                  (item) => item.flavor.toLowerCase() === flavor.toLowerCase()
                );
                if (flavorInfo) {
                  return flavorInfo.unicode;
                }
                return "";
              }
            ),
          });
        } else {
          items_api.push({
            cocktailId: data.cocktailRecommendations[i].cocktailId,
            sourceId: data.cocktailRecommendations[i].sourceId,
            link: data.cocktailRecommendations[i].link,
            title: data.cocktailRecommendations[i].title,
            image: data.cocktailRecommendations[i].image,
            description: data.cocktailRecommendations[i].description,
            cocktailTopFlavors: data.cocktailRecommendations[i].cocktailTopFlavors,
            subtitle: data.cocktailRecommendations[i].cocktailTopFlavors.slice(0, 2).join(" and "),
            buttonText: buttonTextOptions[buttonTextIndex++ % buttonTextOptions.length],
            emojis: data.cocktailRecommendations[i].cocktailTopFlavors.map(
              (flavor) => {
                const flavorInfo = flavorsAndEmojis.find(
                  (item) => item.flavor.toLowerCase() === flavor.toLowerCase()
                );
                if (flavorInfo) {
                  return flavorInfo.unicode;
                }
                return "";
              }
            ),
          });
        }
      }

      setItems(items_api);
      setIsLoading(false);
      if (_geolocation === "1") {
        getGeolocation(params);
      } else {
        saveConsumerData(params, null, null, null, null, null, navigator.userAgent);
          }
    });
  };
        
    const getGeolocation = async (params) => {
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const { ip: userIp } = await ipResponse.json();
        const headers = {
          "x-api-key": "UBgLx9vJ5C1Nwcp117JcU8gB56FNkA7zTa1qMS30",
        };
        const locationResponse = await fetch(
          "https://5mvn5ww1jk.execute-api.eu-west-1.amazonaws.com/stag/geolocation-stag",
          {
            method: "POST",
            headers: headers, // Ensure you define any required headers here
            body: JSON.stringify({ ip: userIp }),
          }
        );
        const locationData = await locationResponse.json();
        if (locationData && locationData.location_info) {
          let { city, country, region, latitude, longitude } =
            locationData.location_info;

          // Validate the data as needed
          if (
            city &&
            country &&
            region &&
            latitude != null &&
            longitude != null
          ) {
            // Return the location data directly
            setLocationData({ city, country, region, latitude, longitude });

            saveConsumerData(params, city, country, region, latitude, longitude, navigator.userAgent);
          }
        }
      } catch {
        const city = null;
        const country = null;
        const region = null;
        const latitude = null;
        const longitude = null;
        // Return the location data directly
        setLocationData({ city, country, region, latitude, longitude });
      }
    };

    const saveConsumerData = async(params, city, country, region, latitude, longitude, user_agent) =>  {
    const consumer_params = {
      consumerId: params.consumerId,
      consumerInformation: 
      {
      city: city,
      country: country,
      region: region,
      latitude: latitude,
      longitude: longitude,
      userAgent: user_agent
    }
    }

    const response = fetch(api_url + 'consumerInformation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': api_key,
        },
        body: JSON.stringify(consumer_params),
      });
    }

    const params = {
      site: _site,
      recipes: [_recipe],
      url: _recipe_url,
      interactionType: "View",
      occasions: [],
      cuisines: [],
      sessionToken: sessionId,
      consumerId: ParentCookie ? ParentCookie : null,
    };

    console.log("params NOW", params)
    
    params.consumerId = ParentCookie
      ? ParentCookie
      : null;
    


    getCocktailsFromFlavourPrintAndGraphQL(params);

    setuserAgentData(navigator.userAgent)

  }, [
    _recipe,
    _site,
    _window_width,
    api_key,
    api_url,
    buttonTextIndex,
    buttonTextOptions,
    cookieName,
    flavorsAndEmojis,
    randomTitle,
    wlanguage,
    useGraphQL
  ]);

  // Perfom interaction save with API

  const saveInteractionAPIFP = async(params) => {
    // Begin Saving Flavour Print --------------------------------
        const saveInteractionsFPRequests = [];
        for (const item of params) {
          const URLFP = new URL(api_url + "interaction");

          
          Object.keys(item).forEach((key) =>
            URLFP.searchParams.append(key, item[key])
            
          );

          let paramsObject = {};
          URLFP.searchParams.forEach((value, key) => {
            paramsObject[key] =
              value === "null" && key === "consumerid" ? null : value;
          });

          saveInteractionsFPRequests.push(
            new Promise(async (resolve) => {
              const fpResponse = await fetch(URLFP, {
                method: "POST",
                headers: {
                  "x-api-key": String(api_key),
                },
              });
              resolve(fpResponse);
            })
          );
        }
        await Promise.all(saveInteractionsFPRequests);
        // End Saving Flavour Print ----------------------------------
  }
  const saveInteractionAPI = async (params) => {
    try {
      let consumer = await GetConsumer(String(params[0].consumerId));

      if (!consumer) {
        consumer = await CreateConsumer(String(params[0].consumerId), useGraphQL);
      }

      if (consumer) {
        const { city, country, region, latitude, longitude } = locationData;
        const user_agent = userAgentData;
        let consumerInteractions = consumer.interactions || [];
        const input = {
          createdAt: consumer.createdAt,
          id: consumer.id,
          interactions: [
            ...consumerInteractions.map((i) => ({
              createdAt: i.createdAt,
              interactionType: i.interactionType,
              site: i.site,
              sourceId: i.sourceId,
              city: city,
              country: country,
              region: region,
              latitude: latitude,
              longitude: longitude,
              user_agent: user_agent
            })),
            ...params.map((interaction) => ({
              createdAt: dayjs().format("YYYY/MM/DD HH:mm"),
              interactionType: interaction.interactionType,
              site: interaction.site,
              sourceId: interaction.sourceId,
              city: city,
              country: country,
              region: region,
              latitude: latitude,
              longitude: longitude,
              user_agent: user_agent
            })),
          ],
        };

        await UpdateConsumer(input);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };
  async function performInteractions(params) {
    try {
      //setIsLoading(true);
      await saveInteractionAPIFP(params);
      await saveInteractionAPI(params);
      return;
    } catch (error) {
      console.error("Error making POST request:", error);
      throw error;
    }
  }
  async function performInteraction(params) {
    try {
      await saveInteractionAPIFP([params]);
      await saveInteractionAPI([params]);
      return;
    } catch (error) {
      console.error("Error making POST request:", error);
      throw error;
    }
  }

  const unicodeToEmoji = (unicodeArray) => {
    return unicodeArray
      .map((unicode) =>
        String.fromCodePoint(parseInt(unicode.replace("U+", ""), 16))
      )
      .join("");
  };

  const showNextItems = () => {
    const sourceIds = items
      .slice(currentIndex, currentIndex + 2)
      .map((element) => element.cocktailId);

    let paramsInteractionNextItems = {};
    const interactions = [];
    sourceIds.forEach((sourceId) => {
      const paramsInteractionNextItems = {
        site: _site,
        sourceId: sourceId,
        consumerId: SessionCookie,
        sessionToken: sessionId,
        interactionType: "pass",
      };

      
      interactions.push(paramsInteractionNextItems);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "custom_event",
        event_name: "WYC_Widget_Pass",
        cocktailId: sourceId,
        click_text: "Pass",
      });

    });

    performInteractions(interactions, useGraphQL);

    const nextMoreOptionsIndex = (clickCount + 1) % MoreOptions.length;
    setTextMoreOptions(MoreOptions[nextMoreOptionsIndex]);
    setClickCount(clickCount + 1);
    const nextIndex = currentIndex + 2;
    if (nextIndex < items.length) {
      setCurrentIndex(nextIndex);
    } else {
      setCurrentIndex(0);
    }
    //window.parent.postMessage({ event: "widget_button_click", buttonName: "Pass" }, "*");
    //console.log({ event: "widget_button_click", buttonName: "Click" });


  };

  const cocktailView = async (item) => {
    const paramsInteractionButton = {
      site: _site,
      sourceId: item.cocktailId,
      consumerId: SessionCookie,
      sessionToken: sessionId,
      interactionType: "recommended",
    };
  

    await performInteraction(paramsInteractionButton);
  };

  const buttonClick = async (item) => {
    const paramsInteractionButton = {
      site: _site,
      sourceId: item.cocktailId,
      consumerId: SessionCookie,
      sessionToken: sessionId,
      interactionType: "Click",
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "custom_event",
      event_name: "WYC_Widget_Click",
      cocktailId: item.cocktailId,
      destination_page_path: item.link,
      click_text: "View Recipe",
    });  
    
   // window.parent.postMessage({ event: "widget_button_click", buttonName: "Click" }, "*");
    //console.log({ event: "widget_button_click", buttonName: "Click" });

    window.open(item.link, "_blank", "noopener,noreferrer");
    await performInteraction(paramsInteractionButton, useGraphQL);
  };

  // Use effect to call buttonClick when items are rendered
  useEffect(() => {
    if (items.length > 0) {
      // Trigger buttonClick when an item is rendered
      items.slice(currentIndex, currentIndex + 2).forEach((item) => {
        cocktailView(item); // Call buttonClick when each item renders
      });
    }
  }, [items, currentIndex]);

  return (
    <div style={{ color: textColor, backgroundColor: backgroundColor }}>
      <h2>{Title}</h2>
      <h2 className="subtitle">{SubTitle}</h2>
      {isLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
          }}
        >
          <BeatLoader color={textColor} />
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="carousel">
            {items.slice(currentIndex, currentIndex + 2).map((item) => (
              
              <React.Fragment key={item.sourceId}>
                {isMobile && (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    name="WidgetClickButton"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      buttonClick(item);
                    }}
                  >
                    <div key={item.sourceId} className="box">
                      <div
                        className="container-image"
                        style={{ backgroundImage: `url(${item.image})` }}
                      ></div>
                      <div className="content-box">
                        <h3>{item.title}</h3>
                        <p className="mini-desc">{item.subtitle}</p>
                        <div className="description">
                          <p className="title-desc">{item.subtitle}</p>
                          <p className="desc">{item.description}</p>
                          <p className="emojis">
                            {unicodeToEmoji(item.emojis)}
                          </p>
                          <input
                            type="hidden"
                            id="hdnSourceId"
                            value={item.cocktailId}
                          />
                        </div>
                        <span
                          name="WidgetClickButton"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            buttonClick(item);
                          }}
                        >
                          {item.buttonText}
                        </span>
                      </div>
                    </div>
                  </a>
                )}
                {!isMobile && (
                  <div key={item.sourceId} className="box desktop">
                    <div
                      className="container-image"
                      style={{ backgroundImage: `url(${item.image})` }}
                    ></div>
                    <div className="content-box">
                      <h3>{item.title}</h3>
                      <p className="mini-desc">{item.subtitle}</p>
                      <div className="description">
                        <p className="title-desc">{item.subtitle}</p>
                        <p className="desc">{item.description}</p>
                        <p className="emojis">{unicodeToEmoji(item.emojis)}</p>
                        <input
                          type="hidden"
                          className="hdnSourceId"
                          value={item.cocktailId}
                        />
                      </div>
                      <span
                        name="WidgetClickButton"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          buttonClick(item);
                        }}
                      >
                        {item.buttonText}
                
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
          <span name="WidgetPassButton" className="more-options-link" onClick={showNextItems}>
            {TextMoreOptions}
          </span>
          {region.toLowerCase() === "uk" ? (
            <Box
              sx={{
                marginTop: "0.5rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "150px !important" }}>
                <Grid container sx={{ textAlign: "center" }}>
                  <Grid item xs={12}>
                    <img
                      src={FontUK}
                      alt="drink-iq-logo"
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : (
            <span className="legal">{LegalText}</span>
          )}
        </div>
      )}
    </div>
  );
}

export default PocDiageo;
